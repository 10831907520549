import {
  Button, Popover, QRCode, Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQrcode, faRepeat, faPlay, faStop,
} from '@fortawesome/pro-regular-svg-icons';

import { usePlayable } from '../../../context/playable.context';
import { IecInputNumber } from '../../input-number';

import './playable-preview.scss';

export function Preview() {
  const uPlayable = usePlayable();

  const [playableFile, _setPlayableFile] = useState<string>('');
  const [isPlaying, _setIsPlaying] = useState<boolean>(false);
  const [hasPlayed, _setHasPlayed] = useState<boolean>(false);
  const [QRContent, _setQRContent] = useState<string>('');
  const [QRContentLoading, _setQRContentLoading] = useState<'loading' | 'active' | 'expired' | undefined>('loading');

  useEffect(() => {
    uPlayable.getPlayableFile().then((file) => _setPlayableFile(file));
    _setIsPlaying(uPlayable?.playableIsPlaying() as boolean);
  }, [uPlayable]);

  useEffect(() => {
    if (isPlaying && !hasPlayed) {
      _setHasPlayed(true);
    }
  }, [hasPlayed, isPlaying]);

  const handleUpdateSize = () => {
    const widthStk = uPlayable.phoneOrientation.width;

    uPlayable.phoneOrientation.width = uPlayable.phoneOrientation.height;
    uPlayable.phoneOrientation.height = widthStk;
    uPlayable.refreshPlayable();
  };

  const togglePlay = () => {
    if (uPlayable?.playableIsPlaying()) {
      uPlayable.pause();
      _setPlayableFile('');
      _setIsPlaying(false);
      _setHasPlayed(false);
      uPlayable.refreshPlayable();
    } else {
      uPlayable.play();
      _setIsPlaying(true);
    }
  };

  const generatePlayableQrcode = async () => {
    _setQRContentLoading('loading');
    _setQRContent('null');
    _setQRContent(await uPlayable.getTryQrcode());
    _setQRContentLoading('active');
  };

  const popoverContent = (): React.ReactNode => {
    if (QRContent) {
      return (
        <QRCode errorLevel="M" value={QRContent} status={QRContentLoading} />
      );
    }
    return (
      <FontAwesomeIcon style={{ width: '134px', height: '134px' }} icon={faQrcode} />
    );
  };

  const loggerScript = `
  <script>
  window.capturedLogs = [];

  const logger = {
    log: console.log,
    warn: console.warn,
    error: console.error,
  };

  console.error = (...args) => {
    capturedLogs.push({type: 'error', message: args});
    logger.error(...args);
  };

  console.warn = (...args) => {
    capturedLogs.push({type: 'warn', message: args});
    logger.warn(...args);
  };

  console.log = (...args) => {
    capturedLogs.push({type: 'log', message: args});
    logger.log(...args);
  };
  </script>
`;
  const modifiedPlayableFile = playableFile.replace('</head>', `${loggerScript}</head>`);

  return (
    <div className="playable-preview">
      <span className="playable-size">
        <IecInputNumber context="PLA" addonBefore="W" field="width" obj={uPlayable.phoneOrientation} />
        <Button
          type="primary"
          shape="circle"
          icon={<FontAwesomeIcon icon={faRepeat} />}
          onClick={handleUpdateSize}
        />
        <IecInputNumber context="PLA" addonBefore="H" field="height" obj={uPlayable.phoneOrientation} />
      </span>
      <div className="actions-column">
        <Popover
          zIndex={5001}
          overlay
          content={popoverContent}
          title={QRContent ? 'Scan your playable!' : 'Generate your Qrcode!'}
        >
          <Button type="primary" shape="circle" icon={<FontAwesomeIcon icon={faQrcode} />} onClick={() => { generatePlayableQrcode(); }} />
        </Popover>
        <Tooltip title={isPlaying ? 'Stop' : 'Play'}>
          <Button
            type="primary"
            shape="circle"
            icon={<FontAwesomeIcon icon={isPlaying ? faStop : faPlay} />}
            onClick={togglePlay}
          />
        </Tooltip>
      </div>
      <div
        key={uPlayable.getPhoneOrientation()}
        className="phone"
        style={{
          width: `${uPlayable.phoneOrientation.width + 16}px`,
          height: `${uPlayable.phoneOrientation.height + 16}px`,
        }}
      >
        <iframe
          onLoad={() => uPlayable.setIsPlayableLoaded(true)}
          ref={uPlayable.playableIframeRef}
          title="iframe-preview"
          srcDoc={modifiedPlayableFile}
          style={{
            width: '200%', height: '200%', transform: 'scale(0.5)', transformOrigin: '0 0',
          }}
        />
      </div>
    </div>
  );
}
